export const ORDERING_MENU_API = "/api/manager/menu";
export const pastOrderAPI = "/api/manager/orders";
export const ORDERING_MENU_ITEMS_API = "/api/manager/items";
export const ORDERING_MENU_CATEGORIES_API = "/api/manager/categories";
export const orderingPortalLinkURL = "/api/manager/web-portal/preview-link";
export const REORDERING_ITEM_API = (id) => `/api/manager/items/${id}/reorder`;
export const REORDERING_CATEGORY_API = (id) => `/api/manager/categories/${id}`;
export const orderTracking = (id) =>
  `/api/manager/orders/order-status-changes/${id}`;
export const extraInfoURL = (id) =>
  `/api/manager/delivery-orders/extra-info/${id}`;
export const branchInfoURL = (id) => `/api/manager/orders/branch/${id}`;
export const orderDetailsURL = (id) => `/api/manager/orders/details/${id}`;
export const statisticsURL = "/api/manager/orders/statistics";
export const customerURL = (customerId) =>
  `/api/manager/orders/customer-info/${customerId}`;
export const orderingWebPortalStatus = "/api/manager/web-portal/status";
export const orderingQrPortalStatusURL = "/api/manager/qr-portal/status";
export const ORDERING_MENU_MODIFIER_GROUPS_API =
  "/api/manager/suggested-modifiers-groups";
export const CREATE_ORDERING_MENU_MODIFIERS_GROUPS_API =
  "/api/manager/suggested-modifiers-groups";
// export const DELETE_ORDERING_MENU_CATEGORY_API  = (id) => `/api/manager/categories/${id}`
export const DELETE_ITEM_API = (id) => `/api/manager/items/${id}`;
export const EDIT_ITEM_API = (id) => `/api/manager/items/${id}`;
export const CHANGE_ITEM_CATEGORY = (id: string) =>
  `/api/manager/items/${id}/move-to-category`;
export const STORE_PICKUP_SETTINGS = "/api/manager/store-settings";
export const STORE_WORKING_HOURS_API =
  "/api/manager/store-settings/working-hours";
export const STORE_SETTINGS_API = "/api/manager/store-settings";

// Working Hours Schedules
export const WORKING_HOURS_SCHEDULES_API = "/api/manager/store-settings/working-hours-schedules";
export const WORKING_HOURS_SCHEDULE_API = (scheduleId: string) =>
  `/api/manager/store-settings/working-hours-schedules/${scheduleId}`;
export const WORKING_HOURS_INTERVALS_API = (scheduleId: string) =>
  `/api/manager/store-settings/working-hours-schedules/${scheduleId}/intervals`;
export const ACTIVATE_SCHEDULE_API = (scheduleId: string) =>
  `/api/manager/store-settings/working-hours-schedules/${scheduleId}/activation`;
export const BRANCH_WORKING_HOURS_SCHEDULE_API = (params: { branch_id: string, schedule_id: string }) =>
  `/api/manager/branch-settings/${params.branch_id}/working-hours-schedules/${params.schedule_id}/intervals`;

export const CREATE_SCHEDULE_API = "/api/manager/store-settings/working-hours-schedules";

export const GET_BRAND_CATEGORY_API = "/api/manager/store/store-category";
export const GET_BRAND_TAGS_API = "/api/manager/store/store-tags";
export const GET_ALL_BRAND_CATEGORIES_API = "/api/manager/store-categories";
export const GET_ALL_BRAND_TAGS_API = "/api/manager/store-tags";
export const getAreasAPI = `/api/get-areas`;
export const getCitiesAPI = `/api/get-cities`;
export const getZonesAPI = `/api/get-sub-districts`;
export const toggleOrderingServices =
  "/api/manager/branch-settings/toggle-ordering-services";
export const getCountriesAPI = "/api/get-countries";
export const getDistrictAPI = "/api/get-districts";
export const orderAPI = (id) => `api/manager/orders/${id}`;
export const getMinOrderingValueAPI =
  "/api/manager/store-settings/minimum-order-value";
export const setMinOrderingValueAPI =
  "/api/manager/store-settings/minimum-order-value";
export const SET_ROUTING_METHOD_API =
  "/api/manager/store-settings/toggle-call-center";
export const setPaymentMethodURL =
  "/api/manager/store-settings/payment-methods";
export const getStoreInfoUrl = (id) => `/api/manager/orders/store/${id}`;
export const importMenuUrl = "/api/manager/import-menu/ordering";
export const menuScrapingStateUrl = "/api/manager/import-menu/ordering/status";
export const DOWNLOAD_EXCEL_SHEET = "/api/manager/excel-menu/download";
export const verifyZoneCsvURL = "/api/manager/excel-zones/verify";
export const uploadZoneCsvURL = "/api/manager/excel-zones/upload";
// store working hours
export const getStoreWorkingHours = "/api/manager/store-settings/working-hours";
export const updateStoreWorkingHours =
  "/api/manager/store-settings/working-hours";
// branch working hours
export const getBranchWorkingHoursURL = (branch_id) =>
  `/api/manager/branch-settings/working-hours/${branch_id}/intervals`;
export const updateBranchesWorkingHoursURL = (schedule_id: string) => `/api/manager/branch-settings/working-hours-schedules/${schedule_id}/intervals`;
// check koinz
export const checkKoinzDeliveryAvailabilityURL = (branch_id) =>
  `/api/manager/branch/check-koinz-delivery-availability/${branch_id}`;

export const trackingEvent = (token) =>
  `/api/dashboard-opened-amplitude-event/${token}`;
// edit qr portal branch status url 
export const editQRPortalBranchStatusURL = (branch_id) =>
  `/api/manager/qr-portal/branch/${branch_id}/status`;
// delete ordering menu url 
export const deleteOrderingMenuURL = "/api/manager/menu"