import { all } from "redux-saga/effects";

import * as SAGAS from "./sagas";
import pickupSagas from "../Components/Pickup/redux-store/sagas/pickupSagas";
import { watchGetSingleBranchSaga } from "../Components/WizardComponents/Branches/AddBranchForm/redux/getSingleBranchSaga";
import * as ManagerSagas from "../Components/Pickup/redux-store/sagas/Manager";
import { watchGetFraudCustomersListSaga } from "./sagas/getFraudCustomersListSaga";
import { watchRemoveFraudCustomerPointsSaga } from "./sagas/removeFraudCustomerPointsSaga";
import { watchBlockFraudCustomerSaga } from "./sagas/blockFraudCustomerSaga";
import { watchReviewFraudCustomerSaga } from "./sagas/reviewFraudCustomerSaga";
import { watchUploadCustomerPointsCSVSaga } from "../Components/Pickup/redux-store/sagas/UploadCustomerPointsCSVSaga";
import watchGetBannerCardsSaga from "./sagas/getBannerCardsSaga";
import watchDeleteBannerCardSaga from "./sagas/deleteBannerCardSaga";
import watchReorderBannerCardSaga from "./sagas/reorderBannerCardSaga";
import watchAddBannerCardSaga from "./sagas/addBannerCardSaga";
import posSagas from "./sagas/pos-sagas";
import promotionsSagas from "./sagas/promotions-sagas";
import menuSagas from "./sagas/menu-sagas";
export default function* rootSaga() {
  yield all([
    SAGAS.watchGetCountriesSaga(),
    SAGAS.watchCreateCompetitionSaga(),
    SAGAS.watchEditCompetitionSaga(),
    SAGAS.watchGetCompetitionsSaga(),
    SAGAS.watchGetCompetitionSaga(),
    SAGAS.watchDeleteCompetitionSaga(),
    SAGAS.watchHasNextCompetitionSaga(),
    SAGAS.watchUserRankingSaga(),
    SAGAS.watchGetStoreSaga(),
    SAGAS.watchEditStoreSaga(),
    SAGAS.watchAddCategorySaga(),
    SAGAS.watchAddStoreItemSaga(),
    SAGAS.watchDeleteCategorySaga(),
    SAGAS.watchDeleteStoreItemSaga(),
    SAGAS.watchEditCategorySaga(),
    SAGAS.watchEditStoreItemSaga(),
    SAGAS.watchGetCategoriesSaga(),
    SAGAS.watchGetStoreItemsSaga(),
    SAGAS.watchPassOnFormValuesSaga(),
    SAGAS.watchAddSpecialItemSaga(),
    SAGAS.watchDeleteSpecialItemSaga(),
    SAGAS.watchEditLoyaltyItemSaga(),
    SAGAS.watchEditSpecialItemSaga(),
    SAGAS.watchGetSpecialItemsSaga(),
    SAGAS.watchGetBranchesSaga(),
    SAGAS.watchAddBranchSaga(),
    SAGAS.watchEditBranchSaga(),
    SAGAS.watchDeleteBranchSaga(),
    SAGAS.watchGetCashiersSaga(),
    SAGAS.watchSignUpSaga(),
    SAGAS.watchUpdateStoreViralitySettingsSaga(),
    SAGAS.watchGetStoreViralitySettingsSaga(),
    SAGAS.watchGetRecommendedGiftSaga(),
    watchDeleteBannerCardSaga(),
    watchGetBannerCardsSaga(),
    watchReorderBannerCardSaga(),
    watchAddBannerCardSaga(),
    watchGetSingleBranchSaga(),
    ManagerSagas.watchUpdateBranchesWorkingHours(),
    ManagerSagas.watchDeleteWorkingHoursSchedule(),
    ManagerSagas.watchActivateWorkingHoursSchedule(),
    ManagerSagas.watchCreateWorkingHoursSchedule(),
    watchRemoveFraudCustomerPointsSaga(),
    watchBlockFraudCustomerSaga(),
    watchReviewFraudCustomerSaga(),
    watchUploadCustomerPointsCSVSaga(),
    ...pickupSagas,
    ...posSagas,
    ...promotionsSagas,
    ...menuSagas,
    watchGetFraudCustomersListSaga(),
  ]);
}
