import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { reducer as form, FormStateMap, FormState } from "redux-form";
import { loadingBarReducer } from "react-redux-loading-bar";
import authReducer from "./reducers/authReducer";
import countriesReducer from "./reducers/countriesReducer";
import createCompetitionReducer from "./reducers/createCompetitionReducer";
import competitionsReducer from "./reducers/competitionsReducer";
import competitionReducer from "./reducers/competitionReducer";
import userRankingReducer from "./reducers/userRankingReducer";
import addMoreReducer from "./reducers/addMoreReducer";
import editCompetitionReducer from "./reducers/editCompetitionReducer";
import collapseReducer from "./reducers/collapseReducer";
import tutorialReducer from "./reducers/tutorialReducer";
import storeReducer from "./reducers/storeReducer";
import menuReducer from "./reducers/menuReducer";
import giftListReducer from "./reducers/giftListReducer";
import branchesReducer from "./reducers/branchesReducer";
import cashiersReducer from "./reducers/cashiersReducer";
import { bannerCardsReducer } from "./reducers/bannerCardsReducer";
import pickupReducers from "../Components/Pickup/redux-store/reducers";
import { getSingleBranchReducer } from "../Components/WizardComponents/Branches/AddBranchForm/redux/getSingleBranchReducer";
import * as reducers from "./reducers";
import { IRecommendedGift } from "../types/wizard-types";
import { fraudlentCustomersReducer } from "./reducers/fraudlentCustomersReducer";
import { BannerCard } from "./actions/brand-visuals-actions";
import * as promotionsReducers from "./reducers/promotions-reducers";
import * as menuReducers from "./reducers/menu-reducers";
import * as posReducers from "./reducers/pos-reducers";
export interface IFormState {
  gift_1: FormState;
  gift_2: FormState;
  gift_3: FormState;
  gift_4: FormState;
  gift_5: FormState;
  confirmationForm: FormState;
  storeForm: FormState;
  addCashierForm: FormState;
  addUserForm: FormState;
  myAccountForm: FormState;
  changePasswordForm: FormState;
  addBlockedUserForm: FormState;
  PickupBranchesForm: FormState;
}

export interface IRootReducerState {
  fraudlentCustomersReducer: any;
  orderTrackingReducer: any;
  orderDetailsReducer: any;
  pastOrdersReducer: any;
  statisticsReducer: any;
  giftListReducer: any;
  getZonesReducer: any;
  getAreasReducer: any;
  authReducer: any;
  orderReducer: any;
  menuReducer: any;
  editBranchZonesReducer: any;
  getBranchInfoReducer: any;
  getSingleBranchReducer: any;
  getCustomerInfoReducer: any;
  getStoreInfoReducer: any;
  extraInfoReducer: any;
  suggestedModifierGroupsReducer: any;
  getMenuScrapingStateReducer: any;
  orderingMenuItemsReducer: any;
  orderingMenuCategoriesReducer: any;
  storeReducer: any;
  orderingMenusReducer: any;
  minOrderValueReducer: any;
  orderingPortalLinkReducer: any;
  orderingPortalStatisticsReducer: any;
  webPortalHistoryReducer: any;
  storeSettingsReduces: ReturnType<typeof pickupReducers.storeSettingsReduces>;
  userRankingReducer: any;
  cashiersReducer: any;
  competitionReducer: any;
  editCompetitionReducer: any;
  createCompetitionReducer: any;
  addMoreReducer: any;
  branchesReducer: ReturnType<typeof branchesReducer>;
  tutorialReducer: any;
  competitionsReducer: any;
  form: FormStateMap;
  updateStoreWorkingHoursReducer: ReturnType<
    typeof pickupReducers.updateStoreWorkingHoursReducer
  >;
  branchesWorkingHoursReducer: ReturnType<
    typeof pickupReducers.branchesWorkingHoursReducer
  >;
  updateBranchesWorkingHoursReducer: ReturnType<
    typeof pickupReducers.updateBranchesWorkingHoursReducer
  >;
  getStoreWorkingHoursReducer: ReturnType<
    typeof pickupReducers.getStoreWorkingHoursReducer
  >;
  submitPickupBranchesReducer: any;
  getOrderingQRPortalStatusReducer: any;
  getStoreViralitySettingsReducer: any;
  updateStoreViralitySettingsReducer: any;
  getRecommendedGiftReducer: { recommended_gift: IRecommendedGift | undefined };
  bannerCardsReducer: {
    bannerCardModalVisible: boolean;
    loading: boolean;
    bannerCards: BannerCard[];
    success: boolean;
    failed: boolean;
    selectedBannerCard: Omit<BannerCard, "id" | "image"> & {
      id?: string;
      image?: string;
    };
  };
  getPosEntitiesReducer: ReturnType<typeof posReducers.getPosEntitiesReducer>;
  getPromotionsReducer: ReturnType<
    typeof promotionsReducers.getPromotionsReducer
  >;
  getVouchersReducer: ReturnType<typeof promotionsReducers.getVouchersReducer>;
  loyaltyMenuReducer: ReturnType<typeof menuReducers.loyaltyMenuReducer>;
  giftItemsListReducer: ReturnType<typeof menuReducers.giftItemsListReducer>;
  multiPosGiftListReducer: ReturnType<
    typeof posReducers.multiPosGiftListReducer
  >;
  editMultiPosGiftListReducer: ReturnType<
    typeof posReducers.editMultiPosGiftListReducer
  >;
  workingHoursScheduleReducer: ReturnType<
    typeof pickupReducers.workingHoursScheduleReducer
  >;
}

const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  form,
  authReducer: persistReducer(
    {
      storage,
      key: "auth",
    },
    authReducer
  ),
  countriesReducer,
  menuReducer,
  createCompetitionReducer,
  userRankingReducer,
  competitionsReducer,
  competitionReducer,
  addMoreReducer,
  editCompetitionReducer,
  collapseReducer,
  tutorialReducer,
  cashiersReducer,
  storeReducer,
  giftListReducer,
  branchesReducer,
  getSingleBranchReducer,
  fraudlentCustomersReducer,
  bannerCardsReducer,
  ...posReducers,
  ...promotionsReducers,
  ...reducers,
  ...pickupReducers,
  ...menuReducers,
} as any);

const persistorOptions: PersistConfig = {
  key: "root",
  storage,
  whitelist: ["appReducer"],
};

const persistedReducer = persistReducer(persistorOptions, rootReducer);

export default persistedReducer;
